import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import Sidebar from '../Sidebar/Sidebar'
import Dialog from '../Sidebar/Dialog'
import { getCallForward } from '../../actions/message.action'

import UserHeader from '../Header/UserHeader'

import './styles.css'
import { addNotifiation, getAllNotifiations, deleteNotifiation } from '../../actions/notification.action'

const Notifications = (props) => {
  const dispatch = useDispatch()
  const { isAdmin } = useSelector((state) => state.message.numbers)
  const { notifications } = useSelector((state) => state.notification)

  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)

  const [openNotification, setOpenNotification] = useState(false)

  const [newNotifcation, setNewNotification] = useState({
    title: '',
    content: '',
    priority: 2,
  })
  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  const toggleNotification = () => {
    setOpenNotification((prevState) => !prevState)
  }

  const saveNotification = () => {
    dispatch(addNotifiation(newNotifcation))
    toggleNotification()
    setNewNotification({
      title: '',
      content: '',
      priority: 2,
    })
  }

  const changeInputValue = (e) => {
    setNewNotification({ ...newNotifcation, [e.target.id]: e.target.value })
  }

  const handleDelete = (item) => {
    if (window.confirm('Are you sure you want to delete this notification?')) {
      dispatch(deleteNotifiation(item._id))
    }
  }

  useEffect(() => {
    if (!isAdmin) {
      props.history.push('/home')
    }
    //eslint-disable-next-line
  }, [isAdmin])

  useEffect(() => {
    dispatch(getAllNotifiations())
    //eslint-disable-next-line
  }, [])

  return (
    <div className="light">
      <ToastContainer autoClose={8000} />
      <div className="layout-admin">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} history={props.history} />
          <div className="numbers">
            <div className="text-right">
              <button className="btn btn-primary" onClick={toggleNotification}>
                <i className="fas fa-plus" />
              </button>
            </div>

            <table className="table table-striped mb-0 client-table mt-4">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Title</th>
                  <th>Content</th>
                  <th>Priority</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {notifications?.length > 0 &&
                  notifications.map((notification, index) => (
                    <tr key={index}>
                      <td className="td-center">{index + 1}</td>
                      <td className="td-center">{notification.title}</td>
                      <td className="td-center">{notification.content}</td>
                      <td className="td-center">{notification.priority}</td>
                      <td className="td-center">
                        <i className="fas fa-trash-alt" onClick={() => handleDelete(notification)}></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openNotification}
        toggle={toggleNotification}
        className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ModalHeader toggle={toggleNotification}>
          <i className="fas fa-bell"></i>Add Notification
        </ModalHeader>
        <ModalBody>
          <div className="mb-3 form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Title"
              value={newNotifcation.title}
              onChange={changeInputValue}
            />
          </div>
          <div className="mb-3 form-group">
            <label htmlFor="content">Content</label>
            <textarea
              className="form-control"
              value={newNotifcation.content}
              id="content"
              placeholder="Content"
              onChange={changeInputValue}
            ></textarea>
          </div>
          <div className="mb-3 form-group">
            <label htmlFor="priority">Priority</label>
            <select className="form-control" id="priority" onChange={changeInputValue}>
              <option value="2">Normal</option>
              <option value="1">Top</option>
            </select>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveNotification}>
            Add
          </Button>
          <Button color="secondary" onClick={toggleNotification}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={openNotification}
        toggle={toggleNotification}
        className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ModalHeader toggle={toggleNotification}>
          <i className="fas fa-bell"></i>Add Notification
        </ModalHeader>
        <ModalBody>
          <div className="mb-3 form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Title"
              value={newNotifcation.title}
              onChange={changeInputValue}
            />
          </div>
          <div className="mb-3 form-group">
            <label htmlFor="content">Content</label>
            <textarea
              className="form-control"
              value={newNotifcation.content}
              id="content"
              placeholder="Content"
              onChange={changeInputValue}
            ></textarea>
          </div>
          <div className="mb-3 form-group">
            <label htmlFor="priority">Priority</label>
            <select className="form-control" id="priority" onChange={changeInputValue}>
              <option value="2">Normal</option>
              <option value="1">Top</option>
            </select>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveNotification}>
            Add
          </Button>
          <Button color="secondary" onClick={toggleNotification}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Notifications
