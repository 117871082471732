import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap'

import { readNotification } from '../../actions/notification.action'

const Notifications = () => {
  const { notifications, readNotifications } = useSelector((state) => state.notification)
  const dispatch = useDispatch()

  const [isShow, setShow] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [newAlarms, setNewAlarms] = useState([])
  const [alarm, setAlarm] = useState(null)
  const toggleShowList = () => setShow((prevState) => !prevState)

  const toggleOpenNotification = () => {
    setOpenNotification((prevState) => !prevState)
  }
  const showAlarm = (item) => {
    setAlarm(item)
    toggleOpenNotification()
  }

  const readAlarm = (item) => {
    toggleOpenNotification()
    if (!readNotifications.includes(item._id)) {
      const alarms = [...readNotifications, item._id]
      dispatch(readNotification(alarms))
    }
  }

  useEffect(() => {
    if (notifications && readNotifications) {
      const result = notifications.filter((item) => {
        const isUnread = !readNotifications.includes(item._id)

        if (isUnread && item.priority === 1) {
          setAlarm(item)
          setOpenNotification(true)
        }

        return isUnread
      })

      setNewAlarms(result)
    }

    // eslint-disable-next-line
  }, [notifications, readNotifications])

  return (
    <>
      <Dropdown isOpen={isShow} toggle={toggleShowList}>
        <DropdownToggle className="btn btn-light btn-head notification-btn position-relative">
          <i className="fas fa-bell" />
          {newAlarms.length > 0 && <div className="notify-badge" />}
        </DropdownToggle>
        <DropdownMenu className="custom-outer-menu" right>
          {newAlarms.length > 0 ? (
            newAlarms.map((alarm, index) => (
              <DropdownItem key={index} className="drop-menu" onClick={() => showAlarm(alarm)}>
                {alarm.title}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem className="drop-menu">No notifications</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={openNotification} className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}>
        <ModalHeader toggle={() => readAlarm(alarm)}>
          <i className="fas fa-bell"></i> {alarm?.title || 'Notification'}
        </ModalHeader>
        <ModalBody>
          <div className="contact">{alarm?.content}</div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Notifications
